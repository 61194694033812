import type {
  EditorScriptFlowAPI,
  FlowEditorSDK,
} from '@wix/yoshi-flow-editor';
import type { ComponentClientSpecMapEntry } from '@wix/editor-platform-sdk-types';

import { GROUPS_APP_DEFINITION_ID } from '../../constants';
import type { EditorPlatformMigration } from './EditorPlatformMigration';

const migrations = require.context('./migrations', false, /\.migration\.ts$/);

export class MigrationAssistant {
  protected migrations: EditorPlatformMigration[] = [];

  constructor(
    protected readonly applicationId: number,
    protected readonly sdk: FlowEditorSDK,
    protected readonly flowAPI: EditorScriptFlowAPI,
  ) {
    this.migrations = migrations
      .keys()
      .map((key) => migrations(key).default)
      .map((Migration) => new Migration(sdk, flowAPI));
  }

  async install() {
    const components = await this.sdk.tpa.app
      .getAllCompsByApplicationId(GROUPS_APP_DEFINITION_ID, this.applicationId)
      .then((components) => components || [])
      .catch(() => []);

    await Promise.all(
      components.map((component) => this.installComponent(component)),
    );
  }

  async migrate() {
    const components = await this.sdk.tpa.app
      .getAllCompsByApplicationId(GROUPS_APP_DEFINITION_ID, this.applicationId)
      .then((components) => components || [])
      .catch(() => []);

    await Promise.all(
      components.map((component) => this.migrateComponent(component)),
    );
  }

  async installComponent(component: ComponentClientSpecMapEntry) {
    for (const migration of this.migrations) {
      try {
        const ref = await this.sdk.components.getById(
          GROUPS_APP_DEFINITION_ID,
          {
            id: component.id,
          },
        );

        const result = await migration.install(component, ref);
        await migration.setMigrationStatus(result, ref);
      } catch (error) {
        this.flowAPI.errorMonitor.captureException(error as Error, {
          contexts: {
            file: { name: 'MigrationAssistant.ts > installComponent' },
          },
        });
      }
    }
  }

  async migrateComponent(component: ComponentClientSpecMapEntry) {
    for (const migration of this.migrations) {
      try {
        const ref = await this.sdk.components.getById(
          GROUPS_APP_DEFINITION_ID,
          {
            id: component.id,
          },
        );

        if (await migration.isMigrationNeeded(ref)) {
          const result = await migration.migrate(component, ref);
          await migration.setMigrationStatus(result, ref);
        }
      } catch (error) {
        this.flowAPI.errorMonitor.captureException(error as Error, {
          contexts: {
            file: { name: 'MigrationAssistant.ts > migrateComponent' },
          },
        });
      }
    }
  }
}
